import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import BlogPost from '../templates/blog-post';
import { graphql } from 'gatsby';
import CodeExample from '../components/CodeExample';
export const _frontmatter = {
  "title": "Dynamic SVG Components",
  "date": "2018-03-12",
  "promo": "grids",
  "description": "How to build fluid, partially stretchy, awesome SVG components",
  "color": "#a926a3"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = BlogPost;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bored of boxes? This is a neat little trick I discovered recently that adds some spice to a UI. By combining the `}<a parentName="p" {...{
        "href": "/post/strong-components/"
      }}>{`modern component mindset`}</a>{` with some of that untapped SVG power and modern fluid layouts we can create incredibly flexible UI components that really shine. Here's how!`}</p>
    <p>{`So I wanted to make these Panel components for `}<a parentName="p" {...{
        "href": "https://gridcritters.com"
      }}>{`Grid Critters`}</a>{` that had this cut-corner sci-fi look:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/chat-component-1.jpg",
        "alt": "SVG chat component"
      }}></img></p>
    <p>{`I wanted them to be flexible: growing and shrinking with the size of the CSS Grid cells they lived in. But I also wanted to preserve those cool sharp angles, no matter the size of the component. Essentially I needed to scale `}<em parentName="p">{`most`}</em>{` of the SVG, while keeping `}<em parentName="p">{`parts`}</em>{` of it a fixed size. I figured it out and the effect is pretty sweet. Watch how the angled parts are always the same size and snugly fit the buttons, while the rest of the panel scales with the available space:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/dynamic-svg.gif",
        "alt": "dynamic svg"
      }}></img></p>
    <p>{`Let me show you how I did it so you can create your own awesome dynamic SVG components.`}</p>
    <h2>{`Start with Basic SVG`}</h2>
    <p>{`The first step is to create the shape you want in SVG. I exported my design straight from Sketch and removed the extra attributes/junk Sketch outputs:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg viewBox="0 0 540 340">
  <g stroke="black" fill="none" strokeWidth="2">
    <path d="M539,1 L1,1 L1,329 L463,329 L493,300 L539,300 Z" />
  </g>
</svg>
`}</code></pre>
    <p>{`This paints a basic shape, as defined in the path's `}<inlineCode parentName="p">{`d`}</inlineCode>{` attribute.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/panel-0.jpg",
        "alt": "panel 0"
      }}></img></p>
    <h2>{`Change SVG Defaults`}</h2>
    <p>{`By default SVG already has the ability to scale the entire vector:`}</p>
    <p><img parentName="p" {...{
        "src": "/gif/svg-scaling.gif",
        "alt": "SVG default scaling"
      }}></img></p>
    <p>{`Notice how the `}<inlineCode parentName="p">{`stroke`}</inlineCode>{` is scaling along with the vector? That's cool but we want the stroke to stay a constant size regardless of vector scale. We can change this by setting the path's `}<inlineCode parentName="p">{`vectorEffect`}</inlineCode>{` to `}<inlineCode parentName="p">{`non-scaling-stroke`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<path 
  vectorEffect="non-scaling-stroke" 
  d="M539,1 L1,1 L1,329 L463,329 L493,300 L539,300 Z" />
`}</code></pre>
    <p>{`Now the vector still scales but the stroke stays the same size:`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/gif/non-scaling-stroke.gif",
        "alt": "SVG non scaling stroke"
      }}></img></p>
    <h2>{`Componentize It`}</h2>
    <p>{`Next up turn the SVG into an actual component so you can make it highly configurable. I'm using React but you can use Vue or whatever you like. `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from "react";

const styles = {
  position: "absolute"
};

export default class Panel extends React.Component {

  render() {
    const strokeSize = 2;

    return (
      <svg
        style={styles}
        viewBox="0 0 540 340"
      >
        <g
          stroke="black"
          strokeWidth={strokeSize}
          fill="none"
        >
          <path
            vectorEffect="non-scaling-stroke"
            d={this.props.path}
          />
        </g>
      </svg>
    );
  }
}

`}</code></pre>
    <p>{`Once it's a component you can reuse and customize it with parameters. In this case I'm passing it the `}<inlineCode parentName="p">{`path`}</inlineCode>{` coordinates to draw when it gets rendered:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import React from 'react';
import Panel from 'Panel';

export default () => {  

  const path = \`
    M539,1
    L1,1 
    L1,329 
    L463,329 
    L493,300
    L539,300
    Z
  \`;

  return ( 
    <Panel path={path} />
  )
}
`}</code></pre>
    <p>{`This SVG component can now be used in many ways, drawing any path we pass it.`}</p>
    <h2>{`Make it Boundary Based`}</h2>
    <p>{`Now that we have a component, let's make it size itself based on how much space it has available. So rather than using fixed coordinates, we'll make the path draw relative to the size of its bounds. Initially let's just define some arbitrary bounds of `}<inlineCode parentName="p">{`540px`}</inlineCode>{` by `}<inlineCode parentName="p">{`350px`}</inlineCode>{`. I like using `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Template_literals"
      }}>{`template literals`}</a>{` for things like this, to conveniently generate the `}<inlineCode parentName="p">{`path`}</inlineCode>{` string.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const bounds = {
  width: 540,
  height: 350
}

const stroke = 1;

const path = \`
  M\${bounds.width - stroke},\${stroke}
  L\${stroke},\${stroke}
  L\${stroke},\${bounds.height - stroke}
  L\${bounds.width - 77},\${bounds.height - stroke}
  L\${bounds.width - 47},\${bounds.height - 30}
  L\${bounds.width - stroke},\${bounds.height - 30}
  Z\`;


return ( 
  <div>
    <Panel bounds={bounds} path={path} />
  </div>
)
`}</code></pre>
    <p>{`SVG path syntax is a bit strange, so I've annotated where each point corresponds to the shape, starting in the top right corner. They key here is that each coordinate point be calculated `}<strong parentName="p">{`relative to the bounds`}</strong>{`. I'm also subtracting the `}<inlineCode parentName="p">{`stroke`}</inlineCode>{` size from all the coordinates touching edges since SVG draws its strokes on the `}<em parentName="p">{`outside`}</em>{` of the path and there's no way to make it draw on them on the inside.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/panel-annotated.jpg",
        "alt": "annotated SVG paths"
      }}></img></p>
    <p>{`The last point is the letter `}<inlineCode parentName="p">{`Z`}</inlineCode>{` which tells the path to close by going back to the first point in the top right corner.`}</p>
    <p>{`The numbers you see correlate to distance from the edges of the bounds. Let's zoom in on the sharp angled corner and take a look.`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/zoom.jpg",
        "alt": "zoom in angles"
      }}></img></p>
    <p>{`Using points relative to the bounds is how we make the component stretch in some places (e.g. top left corner to bottom left corner), and stay fixed in other places (e.g. 77px from the right).`}</p>
    <p>{`It's also important that your SVG object's `}<inlineCode parentName="p">{`viewBox`}</inlineCode>{` be set to the same size as your bounds. This ensures that the SVG uses the same coordinate system as the DOM. The easiest way is to just pass the bounds as another parameter to the component and set it on the SVG:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg viewBox={\`0 0 \${bounds.width} \${bounds.height}\`} >
  ...
</svg>
`}</code></pre>
    <p>{`There! Now our SVG component path is bounds-based and we can change its size simply by changing its bounds! We can make it short and wide:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const bounds = {
  width: 800,
  height: 150
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/short-wide-panel.jpg",
        "alt": "short wide SVG panel"
      }}></img></p>
    <p>{`Or we can make it tall and narrow:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const bounds = {
  width: 300,
  height: 450
}
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/tall-narrow-panel.jpg",
        "alt": "tall narrow SVG panel"
      }}></img></p>
    <p>{`No matter the size of the bounds, the panel scales in both directions perfectly and keeps the sci-fi corner angle unchanged.`}</p>
    <h2>{`Make it Container Based`}</h2>
    <p>{`Now that our component draws its lines based on given bounds, we want to calculate those bounds automatically. There are a lot of ways to accomplish this, but my favorite is a little goodie I shamelessly stole from the brilliant React developer `}<a parentName="p" {...{
        "href": "https://twitter.com/iammerrick"
      }}>{`iammerrick`}</a>{`. The solution is a React component named `}<a parentName="p" {...{
        "href": "https://stackblitz.com/edit/dynamic-svg-4?file=MeasureAndRender.js"
      }}>{`MeasureAndRender`}</a>{` (feel free to check out the src code) that measures the size of its DOM element, and makes those measurements available to its children. It also re-renders with freshly calculated `}<inlineCode parentName="p">{`bounds`}</inlineCode>{` whenever the window is resized.`}</p>
    <p>{`Let's use this `}<inlineCode parentName="p">{`MeasureAndRender`}</inlineCode>{` component instead of hard-coding the bounds:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<MeasureAndRender stretch={true} debounce={1}>
  {bounds => {

    const path = \`
      M\${bounds.width - stroke},\${stroke} 
      L\${stroke},\${stroke} 
      L\${stroke},\${bounds.height - stroke} 
      L\${bounds.width - 77},\${bounds.height - stroke} 
      L\${bounds.width - 47},\${bounds.height - 30}
      L\${bounds.width - stroke},\${bounds.height - 30}
      Z\`;

    return <Panel bounds={bounds} path={path} />
  }}
</MeasureAndRender>
`}</code></pre>
    <p>{`Sweet! Now the SVG component's bounds are calculated automatically, and it stretches to fit beautifully.`}<br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "/gif/calculating-bounds.gif",
        "alt": "calculating bounds"
      }}></img></p>
    <h2>{`Make it Pretty`}</h2>
    <p>{`Just for fun let's replace that boring black stroke with a cool gradient:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<linearGradient
  x1="11.8748878%"
  y1="100%"
  x2="88.1251154%"
  y2="0%"
  id="linearGradient-1"
>
  <stop stopColor="#4656B8" offset="0%" />
  <stop stopColor="#9C02BA" offset="39.9%" />
  <stop stopColor="#5A44BA" offset="74.2%" />
  <stop stopColor="#1485B8" offset="100%" />
</linearGradient>
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/gradient-path.jpg",
        "alt": "gradient path border"
      }}></img></p>
    <p>{`And finally let's give the whole thing a nice little "space glass" looking background:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<pattern
  id="panel-background"
  patternUnits="userSpaceOnUse"
  width="100%"
  height="100%"
>
  <image
    xlinkHref={\`https://gedd.ski/img/chat-panel.png\`}
    preserveAspectRatio="none"
    x="0"
    y="0"
    opacity=".4"
    width="100%"
    height="100%"
  />
</pattern>
`}</code></pre>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/final-result.jpg",
        "alt": "final result"
      }}></img></p>
    <p>{`And there you have it! A gorgeous dynamic SVG component that can be reused, configured, and added to. Because it's dynamic it fits perfectly in a fluid Grid. Because it's configurable it can be used to create all kinds of interesting instances:`}</p>
    <p><img parentName="p" {...{
        "src": "/img/dynamic-svg/both.jpg",
        "alt": "both panels"
      }}></img></p>
    <p>{`Thanks for reading. Here's the final `}<a parentName="p" {...{
        "href": "https://stackblitz.com/edit/dynamic-svg-6?file=Panel.js"
      }}>{`source code`}</a>{` for you to experiment with. Now take what you've learned and go build your own dynamic SVG components! Have fun and as always, I look forward to seeing your creations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      